import React from 'react'
import Container from '../components/ui/Container'
import ItalicHeading from '../components/ui/ItalicHeading'
import PolicyText from '../components/ui/PolicyText'
import Layout from '../components/Layout'
import styled from 'styled-components'
import SEO from '../components/seo'
import { toPage } from '../lib/utils'
import { graphql } from 'gatsby'

const Wrapper = styled.div`
    padding: 40px 0;
`

export default function Page({ data }) {

    const page = toPage(data)

    return <Layout>
        <SEO title="Privacy Policy" />
        <Wrapper>
            <Container>
                <ItalicHeading>{page.title}</ItalicHeading>
                <PolicyText dangerouslySetInnerHTML={{ __html: page.contents }} />
            </Container>
        </Wrapper>
    </Layout >

}

export const query = graphql`
  query PolicyPageQuery {
    page: allPrismicPrivacypolicy {
        edges {
            node {
                data {
                    contents {
                        html
                    }
                    title {
                        text
                    }
                }
            }
        }
    }
}
`
